
import Vue from 'vue'

export default Vue.extend({
  name: 'ErrorAlert',
  props: {
    showError: {},
    errorMessage: {
      type: String,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: undefined,
    },
  },
})
