
import Vue from 'vue'
import ArqanaHeader from '@/components/shared/ArqanaHeader.vue'
import store from './store'
import snackbarStore from './store/snackbar'
import { isMacOS } from './utils/brower'

export default Vue.extend({
  name: 'App',
  data() {
    return {
      store,
      snackbar: snackbarStore.state.snackbar,
      overlay: false,

    }
  },
  computed: {
    isMacOS() {
      return isMacOS()
    },
  },
  components: {
    ArqanaHeader,
  },
  watch: {
    'store.state.expiredToken': function expiredToken(value) {
      if (value) {
        this.$router.push({
          path: '/login',
          query: { redirect: this.$route.fullPath, expired: 'expired' },
        })
      }
    },
  },
})
