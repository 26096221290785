
import Vue from 'vue'
import { AuthenticationResult } from '@azure/msal-browser'
import ErrorAlert from '@/components/alert/ErrorAlert.vue'
import { loginApi } from '@/api/login'
import store from '@/store'
import SsoButton from '@/components/SsoButton.vue'
import { isRequiredGenerator } from '@/utils/rules'
import msalInstance from '@/msal-config'
import { isSafari } from '@/utils/brower'
import { handleAxiosException } from '@/api'

const MESSAGE_ERROR = "Une erreur est survenue lors de l'authentification. Veuillez réessayer ou contacter l'administrateur"
const MESSAGE_ERROR_SSO = "Une erreur est survenue lors de l'authentification SSO. Veuillez réessayer ou contacter l'administrateur"
const MESSAGE_ERROR_USER_DISABLE = "Votre compte est desactivé, veuillez contacter l'administrateur"

const REDIRECT_SSO_LOADING = 'redirect_loading'

export default Vue.extend({
  components: {
    SsoButton,
    ErrorAlert,
  },
  data() {
    return {
      form: {
        password: '',
        username: '',
      },
      errorMessage: '',
      authState: store.state,
      loading: false,
      loadingSso: false,
    }
  },
  methods: {
    isRequiredGenerator,
    routerRedirect() {
      const searchParams = new URLSearchParams(window.location.search)
      const url = searchParams.get('redirect')
      if (url) {
        this.$router.push(url)
      } else {
        this.$router.push('/')
      }
    },
    async login() {
      let msg = ''
      this.errorMessage = ''
      if ((this.$refs.form as HTMLFormElement).validate()) {
        this.loading = true
        try {
          const response = await loginApi.login(this.form.username, this.form.password)
          store.setUserInfo(response)
        } catch (error) {
          msg = handleAxiosException(error, r => r.data?.message ?? MESSAGE_ERROR)
        }
        if (!this.authState.authenticated) {
          this.loading = false
          this.form.password = ''
          this.errorMessage = msg === MESSAGE_ERROR_USER_DISABLE ? msg : 'Mauvais identifiant ou mot de passe'
        } else {
          this.errorMessage = msg
          this.routerRedirect()
        }
      }
    },
    onSsoLoginClick() {
      this.loadingSso = true
      if (isSafari()) {
        sessionStorage.setItem(REDIRECT_SSO_LOADING, 'true')
      }
      this.errorMessage = ''
    },
    async getTokenBySso(responseSso: AuthenticationResult) {
      let msg = ''
      this.errorMessage = ''
      this.loadingSso = true
      if (responseSso.account?.name != null) {
        try {
          const res = await loginApi.loginSso(responseSso.account.name, responseSso.accessToken)
          store.setUserInfo(res)
        } catch (e) {
          msg = handleAxiosException(e)
        }
        if (!this.authState.authenticated) {
          this.loadingSso = false
          this.errorMessage = msg === MESSAGE_ERROR_USER_DISABLE ? msg : MESSAGE_ERROR_SSO
        } else {
          this.errorMessage = msg
          this.routerRedirect()
        }
      } else {
        this.errorMessage = MESSAGE_ERROR_SSO
      }
    },
    showMessageError(message: string) {
      this.errorMessage = message
      this.loading = false
      this.loadingSso = false
    },
  },

  mounted() {
    this.loading = false
    this.loadingSso = sessionStorage.getItem(REDIRECT_SSO_LOADING) !== null
    if (isSafari()) {
      msalInstance.handleRedirectPromise().then((tokenResponse: AuthenticationResult|null) => {
        if (tokenResponse != null && this.loadingSso) {
          this.getTokenBySso(tokenResponse)
        } else {
          this.loadingSso = false
        }
        sessionStorage.removeItem(REDIRECT_SSO_LOADING)
      })
    }
  },
})
