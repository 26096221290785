// eslint-disable import/prefer-default-export
import { format, lightFormat, parseISO } from 'date-fns'

export function formatDate(date?: string, fallback: string|null = '-') : string|null {
  return date && date !== null ? format(parseISO(date), 'dd/MM/yyyy') : fallback
}

export function formatShortDate(date?: string, fallback: string|null = '-') : string|null {
  return date && date !== null ? format(parseISO(date), 'dd/MM/yy') : fallback
}

export function formatDateTime(date?: string, fallback = '-') : string {
  return date ? lightFormat(parseISO(`${date}Z`), 'dd/MM/yyyy à HH:mm') : fallback
}

export function getTodayAsString() : string {
  const today : Date = new Date()
  const currentDay : string = String(today.getDate()).padStart(2, '0')
  const currentMonth : string = String(today.getMonth() + 1).padStart(2, '0')
  const currentYear : string = today.getFullYear().toString()
  return `${currentYear}-${currentMonth}-${currentDay}`
}
