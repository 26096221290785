
import Vue from 'vue'
import ReleaseNotes from '../components/ReleaseNotes.vue'

export default Vue.extend({
  name: 'Home',

  components: {
    ReleaseNotes,
  },
})
