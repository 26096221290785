import { UserRole } from '@/store'
import { AxiosInstance } from 'axios'
import axiosInstance from './index'

interface AuthenticationResponse {
  id: number
  token: string
  username: string
  fullname: string
  role: UserRole
}

class LoginApi {
    private axios: AxiosInstance

    constructor(axios: AxiosInstance) {
      this.axios = axios
    }

    public async login(username: string, password: string): Promise<AuthenticationResponse> {
      const response = await this.axios.post('/login', {
        username,
        password,
      })
      this.axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
      return response.data
    }

    public async loginSso(mail: string, token: string): Promise<AuthenticationResponse> {
      const response = await this.axios.post('/login-microsoft', {
        mail,
        token,
      })
      this.axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
      return response.data
    }
}

export const loginApi = new LoginApi(axiosInstance)
