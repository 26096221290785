import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fr from 'vuetify/src/locale/fr'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#8C1D40',
        primaryLight: '#C9728F',
        secondary: '#280412',
        accent: '#BC9B6A',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
})
