
import Vue from 'vue'
import { setTag } from '@sentry/vue'

export default Vue.extend({
  mounted() {
    setTag('test', this.$route.query.test as string)
    this.$router.push('/login')
  },
})
