import snackbarStore from './snackbar'

// const KEY_TOKEN = 'token'
const KEY_USER_INFO = 'user_info'

export type UserRole = 'ADMIN' | 'ACCOUNTANT' | 'ACCOUNTANT_MANAGER' | 'EXTERNE' | 'TECHNICAL' | 'COMMUNICATION' | 'MANAGER' | 'BLOODSTOCK' | 'INTERN' | 'CUSTOMER'

interface UserInfo {
  id: number
  token: string
  username?: string
  fullname?: string
  role: UserRole
  docusignToken?: string
  docusignExpiration?: number
}

const userInfoJson = localStorage.getItem(KEY_USER_INFO)

const userInfo = userInfoJson == null ? undefined : JSON.parse(userInfoJson) as UserInfo
const authenticated = userInfo !== undefined

const store = {
  debug: true,
  state: {
    authenticated,
    userInfo: userInfo as UserInfo|undefined,
    expiredToken: false,
  },
  setUserInfo(value: UserInfo): void {
    this.state.userInfo = value
    localStorage.setItem(KEY_USER_INFO, JSON.stringify(value))
    this.state.authenticated = this.state.userInfo !== undefined
  },
  logout(): void {
    this.state.userInfo = undefined
    localStorage.removeItem(KEY_USER_INFO)
    this.state.authenticated = this.state.userInfo !== undefined
  },
  expireToken(): void {
    this.state.expiredToken = true
    this.state.userInfo = undefined
    localStorage.removeItem(KEY_USER_INFO)
    this.state.authenticated = this.state.userInfo !== undefined
  },

  isDocusignTokenExpired(): boolean {
    return !this.state.userInfo?.docusignToken || !this.state.userInfo.docusignExpiration || Math.abs(this.state.userInfo.docusignExpiration - Date.now()) / 36e5 > 7
  },
  setDocusignToken(token: string): void {
    if (this.state.userInfo && token) {
      this.setUserInfo({ ...this.state.userInfo, docusignToken: token, docusignExpiration: Date.now() })
    } else {
      snackbarStore.setError('Echec, veuillez vous reconnectez à l\'application Arqana')
      this.logout()
    }
  },

}

const canAccessList: Record<string, UserRole[]> = {
  home: [],
  customer: ['ADMIN', 'ACCOUNTANT_MANAGER', 'ACCOUNTANT', 'TECHNICAL', 'COMMUNICATION', 'MANAGER', 'BLOODSTOCK'],
  horse: ['ADMIN', 'ACCOUNTANT_MANAGER', 'ACCOUNTANT', 'EXTERNE', 'TECHNICAL', 'COMMUNICATION', 'MANAGER', 'BLOODSTOCK'],
  technical: ['ADMIN', 'EXTERNE', 'TECHNICAL', 'COMMUNICATION', 'MANAGER', 'BLOODSTOCK'],
  compta: ['ADMIN', 'ACCOUNTANT_MANAGER', 'ACCOUNTANT', 'MANAGER', 'BLOODSTOCK'],
  users: ['ADMIN'],
}

const canSeeList: Record<string, UserRole[]> = {
  accountantInfo: ['ADMIN', 'ACCOUNTANT_MANAGER', 'ACCOUNTANT', 'MANAGER'],
  admin: ['ADMIN'],
}

const canWriteList: Record<string, UserRole[]> = {
  technical: ['ADMIN', 'TECHNICAL', 'MANAGER'],
  technical_litigation: ['ADMIN', 'MANAGER'],
  compta: ['ADMIN', 'ACCOUNTANT_MANAGER', 'ACCOUNTANT', 'MANAGER'],
  comptaBanking: ['ADMIN', 'ACCOUNTANT_MANAGER', 'MANAGER'],
}

const canUpdateList: Record<string, UserRole[]> = {
  compta: ['ADMIN', 'ACCOUNTANT_MANAGER'],
}

export function canAccess(part: string): boolean {
  if (store.state.userInfo === undefined) {
    return false
  } else {
    return (canAccessList[part] || []).includes(store.state.userInfo.role)
  }
}

export function canSee(part: string): boolean {
  if (store.state.userInfo === undefined) {
    return false
  } else {
    return (canSeeList[part] || []).includes(store.state.userInfo.role)
  }
}

export function canWrite(part: string): boolean {
  if (store.state.userInfo === undefined) {
    return false
  } else {
    return (canWriteList[part] || []).includes(store.state.userInfo.role)
  }
}

export function canUpdate(part: string): boolean {
  if (store.state.userInfo === undefined) {
    return false
  } else {
    return (canUpdateList[part] || []).includes(store.state.userInfo.role)
  }
}

export default store
