
import Vue from 'vue'
import { formatDate } from '@/utils/dates'

export default Vue.extend({
  name: 'ReleaseNotes',
  data: () => ({
    jsonData: '',
    panel: [0],
  }),
  methods: {
    formatDate,
    getReleaseNotes() {
      fetch('release-notes.json')
        .then(response => response.json())
        .then(data => {
          this.jsonData = data
          return data
        })
    },
  },
  mounted() {
    this.getReleaseNotes()
  },
})
