import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CurrentTestVue from '../views/CurrentTest.vue'
import LogoutVue from '../views/Logout.vue'
import store from '../store/index'
import routes, { ExtendedRoute } from './routes'

Vue.use(VueRouter)

const hardRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/NotFoundError',
    name: 'NotFoundError',
    component: () => import('../views/NotFoundError.vue'),
  },

  // pour test e2e
  {
    path: '/current-test',
    name: 'CurrentTest',
    component: CurrentTestVue,
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutVue,
  },

  {
    path: '*',
    redirect: '/NotFoundError',
  },
] as ExtendedRoute[]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.concat(hardRoutes),
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'CurrentTest' && to.name !== 'info' && !store.state.authenticated) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (to.name === 'Login' && store.state.authenticated) {
    next({ path: '/' })
  } else {
    next()
  }
})

export default router
