import '@mdi/font/css/materialdesignicons.css'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import Vue from 'vue'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import App from './App.vue'
import ErrorAlertVue from './components/alert/ErrorAlert.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

Vue.use(VueTelInputVuetify, {
  vuetify,
})

Vue.component('error-alert', ErrorAlertVue)

// Configuration Sentry
if (process.env.NODE_ENV === 'production' || process.env.VUE_APP_SENTRY === 'true') {
  console.info('[Sentry] Activated', process.env.VUE_APP_BASE_BACK_URL)

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_URL_DSN_SENTRY,
    tunnel: `${process.env.VUE_APP_BASE_BACK_URL}/api/sentry`,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'todo-my-site-url.com', /^\//],
      }),
    ],
    logErrors: true,
    environment: process.env.VUE_APP_MODE,
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // Be sure to lower this in production
  })
  Sentry.setTag('build.revision', process.env.VUE_APP_GIT_HASH)
  Sentry.setTag('build.date', process.env.VUE_APP_BUILD_DATE)
}

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
