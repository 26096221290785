export type Browser = 'chrome' | 'firefox' | 'safari' | 'opera' | 'edge' | null

export type OS = 'Windows OS' | 'MacOS' | 'UNIX OS' | 'Linux OS' | null

export function browserDetect(): Browser {
  const { userAgent } = navigator
  let browserName: Browser

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge'
  } else {
    browserName = null
  }

  return browserName
}

export function isSafari(): boolean {
  return browserDetect() === 'safari'
}

export function osDetect(): OS {
  if (navigator.appVersion.indexOf('Win') !== -1) { return 'Windows OS' }
  if (navigator.appVersion.indexOf('Mac') !== -1) { return 'MacOS' }
  if (navigator.appVersion.indexOf('X11') !== -1) { return 'UNIX OS' }
  if (navigator.appVersion.indexOf('Linux') !== -1) { return 'Linux OS' }

  return null
}

export function isMacOS(): boolean {
  return osDetect() === 'MacOS'
}
