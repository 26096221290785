import axios, { AxiosResponse } from 'axios'
import store from '@/store'
import { logException } from '@/utils/log'
import qs from 'qs'
// import LoginApi from './login'
// import AccountApi from './account'
// import RoomApi from './room'
// import SessionApi from './session'
// import MyProfileApi from './myprofile'
// import GameApi from './game'
// import NewsApi from './news'
// import GameHistoryApi from './gameHistory'
// import ReportBugApi from './reportBug'
// import { captureException } from '@sentry/browser'

const token = store.state.userInfo?.token

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})

axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response?.status === 401 && store.state.authenticated) {
    store.expireToken()
  }
  return Promise.reject(error)
})

/*
  axiosInstance.interceptors.response.use(undefined, (error) => {
  const response = error.response
  if (response?.status === 401 && !error.config.url.endsWith('/auth')) {
    store.commit('setExpiredToken', true)
  } // eslint-disable-line brace-style
  // TODO a supprimer quand il y aura une meilleur gestion des erreurs
  else if (!error.config.url.endsWith('/auth') && !error.config.url.endsWith('/account')) {
    captureException(error)
  }
  return Promise.reject(error)
})
*/

/*
if (token) {
  axiosInstance.defaults.headers.common.Authorization = 'Bearer ' + token
}
*/

/*
export const loginApi = new LoginApi(axiosInstance)
export const accountApi = new AccountApi(axiosInstance)
export const roomApi = new RoomApi(axiosInstance)
export const sessionApi = new SessionApi(axiosInstance)
export const myProfileApi = new MyProfileApi(axiosInstance)
export const gameApi = new GameApi(axiosInstance)
export const newsApi = new NewsApi(axiosInstance)
export const gameHistoryApi = new GameHistoryApi(axiosInstance)
export const reportBugApi = new ReportBugApi(axiosInstance, store)
*/

export default axiosInstance

export function handleAxiosException(e: unknown, onResponseCallback: (((r: AxiosResponse) => string | undefined | null) | string) = (r) => r.data?.message): string {
  if (axios.isAxiosError(e)) {
    if (e.code === 'ERR_NETWORK') {
      return 'error.newtork'
    } else if (e.response) {
      if (e.response.status === 409) {
        return 'On dirait quelqu\'une autre personne ait modifié cette donnée pendant vos modifications. Notez vos modifications de côté, rafraîchissez la page et ressaisissez vos informations.'
      }
      if (typeof onResponseCallback === 'string') {
        return onResponseCallback
      } else {
        return onResponseCallback(e.response) ?? 'Une erreur non prévue s\'est produite. Veuillez réessayer.'
      }
    } if (e.request) {
      logException(e)
      return 'La requête n\'a pu aboutir. Veuillez vérifier votre connexion internet.'
    } else {
      logException(e)
      return 'Une erreur s\'est produite. Veuillez réessayer ou bien contacter l\'administrateur du site.'
    }
  }

  logException(e)
  return 'Une erreur s\'est produite. Veuillez réessayer ou bien contacter l\'administrateur du site.'
}

export async function handleAxiosExceptionOnBlobType(e: unknown, onResponseCallback: (((r: AxiosResponse) => string | undefined | null) | string) = (r) => r.data?.message): Promise<string> {
  if (axios.isAxiosError(e)) {
    if (e.code === 'ERR_NETWORK') {
      return 'error.newtork'
    } else if (e.response) {
      if (e.response.status === 409) {
        return 'On dirait quelqu\'une autre personne ait modifié cette donnée pendant vos modifications. Notez vos modifications de côté, rafraîchissez la page et ressaisissez vos informations.'
      }
      if (typeof onResponseCallback === 'string') {
        return onResponseCallback
      } else {
        if (
          e.request.responseType === 'blob'
          && e.response.data instanceof Blob
          && e.response.data.type
          && e.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          return (JSON.parse(await e.response.data.text())).message
        }
        return onResponseCallback(e.response) ?? 'Une erreur non prévue s\'est produite. Veuillez réessayer.'
      }
    } if (e.request) {
      logException(e)
      return 'La requête n\'a pu aboutir. Veuillez vérifier votre connexion internet.'
    } else {
      logException(e)
      return 'Une erreur s\'est produite. Veuillez réessayer ou bien contacter l\'administrateur du site.'
    }
  }

  logException(e)
  return 'Une erreur s\'est produite. Veuillez réessayer ou bien contacter l\'administrateur du site.'
}
