import { RouteConfig } from 'vue-router'
import { canAccess } from '../store/index'
import Login from '../views/Login.vue'

export type ExtendedRoute = RouteConfig & {
  text?: string,
  display?: boolean | (() => boolean),
}

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    display: false,
  },
  {
    path: '/profil',
    name: 'Profil',
    // beforeEnter: (to, from, next) => {
    //   if (canAccess('customer')) {
    //     next()
    //   } else {
    //     next({ name: 'NotFoundError', replace: true })
    //   }
    // },
    // display: () => canAccess('admin'),
    component: () => import('../views/Profil.vue'),
    text: 'Profil',
    display: false,
  },
  {
    path: '/client',
    name: 'Customer',
    beforeEnter: (to, from, next) => {
      if (canAccess('customer')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    display: () => canAccess('customer'),
    component: () => import('../views/Customer.vue'),
    text: 'Client',
  },
  {
    path: '/client/:customerId',
    component: () => import('../views/customer/DetailCustomer.vue'),
    display: false,
    props: route => ({ customerId: parseInt(route.params.customerId, 10) }),
    children: [
      {
        path: '/',
        name: 'DetailCustomerInfo',
        component: () => import('../views/customer/DetailCustomerInfo.vue'),
      },
      {
        path: 'ventes',
        name: 'DetailCustomerVente',
        component: () => import('../views/customer/DetailCustomerVente.vue'),
      },
      {
        path: 'factures',
        name: 'DetailCustomerBill',
        component: () => import('../views/customer/DetailCustomerBill.vue'),
      },
      {
        path: 'headers',
        name: 'DetailCustomerHeader',
        component: () => import('../views/customer/DetailCustomerHeader.vue'),
      },
      {
        path: 'newsletters',
        name: 'DetailCustomerNewsletters',
        component: () => import('../views/customer/DetailCustomerNewsletters.vue'),
      },
    ],
  },
  {
    path: '/cheval',
    name: 'Horse',
    beforeEnter: (to, from, next) => {
      if (canAccess('horse')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/Horse.vue'),
    text: 'Cheval',
    display: () => canAccess('horse'),
  },
  {
    path: '/cheval/:horseId',
    component: () => import('../views/horse/DetailHorse.vue'),
    display: false,
    props: route => ({ horseId: parseInt(route.params.horseId, 10) }),
    children: [
      {
        path: '/',
        name: 'DetailHorseInfo',
        component: () => import('../views/horse/DetailHorseInfo.vue'),
        props: route => ({ horseId: parseInt(route.params.horseId, 10) }),
      },
      {
        path: 'performances',
        name: 'DetailHorsePerformances',
        component: () => import('../views/horse/DetailHorsePerformances.vue'),
        props: route => ({ horseId: parseInt(route.params.horseId, 10) }),
      },
      {
        path: 'production',
        name: 'DetailHorseProduction',
        component: () => import('../views/horse/DetailHorseProduction.vue'),
        props: route => ({ horseId: parseInt(route.params.horseId, 10) }),
      },
      {
        path: 'ventes',
        name: 'DetailHorseVente',
        component: () => import('../views/horse/DetailHorseVente.vue'),
        props: route => ({ horseId: parseInt(route.params.horseId, 10) }),
      },
      {
        path: 'pedigree',
        name: 'DetailHorsePedigree',
        component: () => import('../views/horse/DetailHorsePedigree.vue'),
        props: route => ({ horseId: parseInt(route.params.horseId, 10) }),
      },
    ],
  },
  {
    path: '/technique',
    name: 'Technical',
    beforeEnter: (to, from, next) => {
      if (canAccess('technical')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/TechnicalSales.vue'),
    text: 'Technique',
    display: () => canAccess('technical'),
  },
  {
    path: '/technique/groupe/:saleGroupId',
    beforeEnter: (to, from, next) => {
      if (canAccess('technical')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/technical-sale/DetailTechnicalSaleGroup.vue'),
    props: route => ({ saleGroupId: parseInt(route.params.saleGroupId, 10) }),
    display: false,
    children: [
      {
        path: '/',
        name: 'DetailTechnicalSaleGroupInfo',
        component: () => import('../views/technical-sale/DetailTechnicalSaleGroupInfo.vue'),
        props: route => ({ saleGroupId: parseInt(route.params.saleGroupId, 10) }),
      },
      {
        path: 'lots',
        name: 'DetailTechnicalSaleGroupLots',
        component: () => import('../views/technical-sale/DetailTechnicalSaleGroupLots.vue'),
        props: route => ({ saleGroupId: parseInt(route.params.saleGroupId, 10) }),
      },
      {
        path: 'forfaits',
        name: 'DetailTechnicalSaleGroupForfaits',
        component: () => import('../views/technical-sale/DetailTechnicalSaleGroupForfaits.vue'),
        props: route => ({ saleGroupId: parseInt(route.params.saleGroupId, 10) }),
      },
      {
        path: 'documents',
        name: 'DetailTechnicalSaleGroupDocuments',
        component: () => import('../views/technical-sale/DetailTechnicalSaleGroupDocuments.vue'),
        props: route => ({ saleGroupId: parseInt(route.params.saleGroupId, 10) }),
      },
      {
        path: 'media',
        name: 'DetailTechnicalSaleGroupMedia',
        component: () => import('../views/technical-sale/DetailTechnicalSaleGroupMedia.vue'),
        props: route => ({ saleGroupId: parseInt(route.params.saleGroupId, 10) }),
      },
      {
        path: 'pedigrees',
        name: 'DetailTechnicalSaleGroupPedigrees',
        component: () => import('../views/technical-sale/DetailTechnicalSaleGroupPedigrees.vue'),
        props: route => ({ saleGroupId: parseInt(route.params.saleGroupId, 10) }),
      },
    ],
  },
  {
    path: '/technique/vente/:saleId',
    beforeEnter: (to, from, next) => {
      if (canAccess('technical')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/technical-sale/DetailTechnicalSale.vue'),
    props: route => ({ saleId: parseInt(route.params.saleId, 10) }),
    display: false,
    children: [
      {
        path: '/',
        name: 'DetailTechnicalSaleInfo',
        component: () => import('../views/technical-sale/DetailTechnicalSaleInfo.vue'),
        props: route => ({ saleId: parseInt(route.params.saleId, 10) }),
      },
      {
        path: 'lots',
        name: 'DetailTechnicalSaleLots',
        component: () => import('../views/technical-sale/DetailTechnicalSaleLots.vue'),
        props: route => ({ saleId: parseInt(route.params.saleId, 10) }),
      },
      {
        path: 'updates',
        name: 'DetailTechnicalSaleUpdates',
        component: () => import('../views/technical-sale/DetailTechnicalSaleUpdates.vue'),
        props: route => ({ saleId: parseInt(route.params.saleId, 10) }),
      },
      {
        path: 'registrations',
        name: 'DetailTechnicalSaleRegistrations',
        component: () => import('../views/technical-sale/DetailTechnicalSaleRegistrations.vue'),
        props: route => ({ saleId: parseInt(route.params.saleId, 10) }),
      },
      {
        path: 'supplements',
        name: 'DetailTechnicalSaleInscriptions',
        component: () => import('../views/technical-sale/DetailTechnicalSaleSupplements.vue'),
        props: route => ({ saleId: parseInt(route.params.saleId, 10) }),
      },
      {
        path: 'registration-tickets',
        name: 'DetailTechnicalSaleRegistrationTickets',
        component: () => import('../views/technical-sale/DetailTechnicalSaleRegistrationTickets.vue'),
        props: route => ({ saleId: parseInt(route.params.saleId, 10) }),
      },
    ],
  },
  {
    path: '/registration/:registrationId',
    name: 'DetailTechnicalSaleGroupRegistrationValidation',
    beforeEnter: (to, from, next) => {
      if (canAccess('technical')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/technical-sale/DetailTechnicalSaleRegistrationValidation.vue'),
    props: route => ({ registrationId: parseInt(route.params.registrationId, 10) }),
    display: false,
  },
  {
    path: '/technique/lot/:id',
    beforeEnter: (to, from, next) => {
      if (canAccess('technical')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/technical-lot/DetailTechnicalLot.vue'),
    props: route => ({ lotId: parseInt(route.params.id, 10) }),
    display: false,
    children: [
      {
        path: '/',
        name: 'DetailTechnicalLotInfo',
        component: () => import('../views/technical-lot/DetailTechnicalLotInfo.vue'),
      },
      {
        path: 'documents',
        name: 'DocumentsLotTechnical',
        component: () => import('../views/technical-lot/DetailTechnicalLotDocuments.vue'),
      },
      {
        path: 'adjudication',
        name: 'DetailLotAdjudication',
        component: () => import('../views/technical-lot/DetailLotAdjudication.vue'),
      },
      {
        path: 'litigation',
        name: 'DetailLotLitigation',
        component: () => import('../views/technical-lot/DetailLotLitigation.vue'),
      },
      {
        path: 'catalogue',
        name: 'DetailTechnicalLotCatalogue',
        component: () => import('../views/technical-lot/DetailTechnicalLotCatalogue.vue'),

      },
    ],
  },
  {
    path: '/comptabilite',
    beforeEnter: (to, from, next) => {
      if (canAccess('compta')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/comptabilite/Comptabilite.vue'),
    text: 'Comptabilité',
    redirect: { name: 'LotsComptablesMenu' },
    display: () => canAccess('compta'),
    children: [
      {
        path: 'lots-comptables',
        name: 'LotsComptablesMenu',
        text: 'Lots Comptables',
        component: () => import('../views/comptabilite/LotsComptables.vue'),
      },
      {
        path: 'reglement',
        name: 'Reglements',
        text: 'Saisie des Règlements',
        component: () => import('../views/comptabilite/reglements/Reglement.vue'),
        redirect: { name: 'SaisieReglements' },
        children: [
          {
            path: 'saisie-reglement',
            name: 'SaisieReglements',
            component: () => import('../views/comptabilite/reglements/SaisieReglement.vue'),
          },
          {
            path: 'vendeur',
            name: 'ReglementVendeur',
            component: () => import('../views/comptabilite/reglements/ReglementVendeur.vue'),
          },
          {
            path: 'banque',
            name: 'Banque',
            component: () => import('../views/comptabilite/reglements/Banque.vue'),
          },
          {
            path: 'manage-reglement',
            name: 'ManageReglement',
            component: () => import('../views/comptabilite/reglements/ManageReglement.vue'),
          },
          {
            path: 'compensation',
            name: 'Compensation',
            component: () => import('../views/comptabilite/reglements/ReglementCompensation.vue'),
          },
          {
            path: 'credit',
            name: 'Credit',
            component: () => import('../views/comptabilite/reglements/Credit.vue'),
          },
          {
            path: 'reglement-ordre',
            name: 'ReglementOrdre',
            component: () => import('../views/comptabilite/ReglementOrdre.vue'),
            display: false,
          },
        ],
      },
      {
        path: 'recouvrement',
        name: 'Recouvrement',
        text: 'Recouvrement',
        component: () => import('../views/comptabilite/Recouvrement.vue'),
      },
      {
        path: 'commissions',
        name: 'Commissions',
        text: 'Commissions',
        component: () => import('../views/comptabilite/Commissions.vue'),
      },
      {
        path: 'protocole',
        name: 'Protocole',
        text: 'Protocole',
        component: () => import('../views/comptabilite/protocoles/Protocoles.vue'),
      },
    ],
  },
  {
    path: '/comptable/lot/:id',
    component: () => import('../views/lot/LotAccounting.vue'),
    redirect: { name: 'DashboardLotAccounting' },
    display: false,
    props: route => ({ lotId: parseInt(route.params.id, 10) }),
    children: [
      {
        path: 'dashboard',
        name: 'DashboardLotAccounting',
        component: () => import('../views/lot/DashboardLotAccounting.vue'),
      },
      {
        path: 'detail',
        name: 'DetailLotAccounting',
        component: () => import('../views/lot/DetailLotAccounting.vue'),
      },
      {
        path: 'litigation',
        name: 'DetailLotAccountingLitigation',
        component: () => import('../views/lot/DetailLotAccountingLitigation.vue'),
      },
      {
        path: 'late-payment',
        name: 'LatePaymentLotAccounting',
        component: () => import('../views/lot/LatePaymentLotAccounting.vue'),
      },
    ],
  },
  {
    path: '/vente-compta/:accountingSaleId',
    component: () => import('../views/accountingSale/AccountingSale.vue'),
    display: false,
    props: route => ({ accountingSaleId: parseInt(route.params.accountingSaleId, 10) }),
    children: [
      {
        path: '/',
        name: 'DetailAccountingSale',
        component: () => import('../views/accountingSale/DetailAccountingSale.vue'),
        props: route => ({ accountingSaleId: parseInt(route.params.accountingSaleId, 10) }),
      },
      {
        path: 'editions',
        name: 'EditBillAccountingSale',
        component: () => import('../views/accountingSale/EditBillAccountingSale.vue'),
        props: route => ({ accountingSaleId: parseInt(route.params.accountingSaleId, 10) }),
      },
      {
        path: 'my-arqana-owners',
        name: 'ConfirmMyArqanaOwners',
        component: () => import('../views/accountingSale/ConfirmMyArqanaOwners.vue'),
        props: route => ({ accountingSaleId: parseInt(route.params.accountingSaleId, 10) }),
      },
      {
        path: 'mandat',
        name: 'ConfirmMandat',
        component: () => import('../views/accountingSale/ConfirmMandat.vue'),
        props: route => ({ accountingSaleId: parseInt(route.params.accountingSaleId, 10) }),
      },
      {
        path: 'accounting-reports',
        name: 'AccountingReports',
        component: () => import('../views/accountingSale/AccountingReports.vue'),
        props: route => ({ accountingSaleId: parseInt(route.params.accountingSaleId, 10) }),
      },
    ],
  },
  {
    path: '/utilisateur',
    name: 'Users',
    beforeEnter: (to, from, next) => {
      if (canAccess('users')) {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/Users.vue'),
    text: 'Utilisateurs',
    display: () => canAccess('users'),
  },
  {
    path: '/utilisateur-externe',
    name: 'ExternalUsers',
    component: () => import('../views/ExternalUsers.vue'),
    text: 'Comptes Ext.',
    display: true,
  },
  {
    path: '/report',
    name: 'Rapports',
    // beforeEnter: (to, from, next) => {
    //   if (canAccess('reports')) {
    //     next()
    //   } else {
    //     next({ name: 'NotFoundError', replace: true })
    //   }
    // },
    component: () => import('../views/report/Reports.vue'),
    text: 'Rapports',
  },
  {
    path: '/examples',
    name: 'Examples',
    beforeEnter: (to, from, next) => {
      if (process.env?.NODE_ENV === 'development') {
        next()
      } else {
        next({ name: 'NotFoundError', replace: true })
      }
    },
    component: () => import('../views/Examples.vue'),
    text: 'Exemples composants',
    display: process.env?.NODE_ENV === 'development',
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/Info.vue'),
    text: 'Information de Build',
    display: false,
  },
  {
    path: '/cgu',
    name: 'CGU',
    component: () => import('../views/CGU.vue'),
    text: 'Exemples composants',
    display: false,
  },
  {
    path: '/docusign',
    name: 'docusignLogin',
    component: () => import('../views/docusign/AutoCloseConnexion.vue'),
    display: false,
  },
] as Array<ExtendedRoute>
