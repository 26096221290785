/* eslint-disable no-console */
import { captureException, captureMessage } from '@sentry/vue'

export function logWarning(message: string): void {
  captureMessage(message, 'warning')
  console.warn(message)
}

export function logException(message: unknown): void {
  if (typeof message === 'string') {
    captureMessage(message, 'error')
    console.error(message)
  } else {
    captureException(message)
    console.error(message)
  }
}
