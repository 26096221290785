import { PublicClientApplication } from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    clientSecretId: process.env.VUE_APP_CLIENT_SECRET_ID,
    authority:
            `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
    redirectUri: `${process.env.VUE_APP_URL_SSO}/login`,
  },
}
const msalInstance = new PublicClientApplication(msalConfig)

export default msalInstance
