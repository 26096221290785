
import Vue from 'vue'
import store from '@/store'
import routes from '@/router/routes'
import christmasSound from '@/assets/audio/christmas_sound.mp3'

export default Vue.extend({
  name: 'ArqanaHeader',

  data: () => ({
    dialogLogout: false,
    authState: store.state,
    delay: undefined as number | undefined,
    mini: true,
    tab: null,

    christmasSound: undefined as HTMLAudioElement|undefined,
    isPlaying: false,
  }),
  methods: {
    switchDialogLogout() {
      this.dialogLogout = !this.dialogLogout
    },
    logout() {
      store.logout()
      this.$router.push('/login')
      this.dialogLogout = false
    },
    extend() {
      this.delay = setTimeout(() => {
        this.mini = false
      }, 250)
    },
    collapse() {
      clearTimeout(this.delay)
      this.mini = true
    },
    accountInitials() : string {
      if (!this.fullname) {
        return ''
      } else {
        const name = this.fullname.split(' ')
        return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`
      }
    },
    onPlayClick() {
      if (this.isPlaying) {
        // eslint-disable-next-line no-unused-expressions
        this.christmasSound?.pause()
      } else {
        // eslint-disable-next-line no-unused-expressions
        this.christmasSound?.play()
      }
      this.isPlaying = !this.isPlaying
    },
  },
  computed: {
    activeItems() {
      return routes.filter(route => {
        if (typeof route.display === 'function') {
          return route.display()
        } else {
          return route.display !== false
        }
      })
    },
    showHeader() {
      return this.$route.name !== 'Login'
    },
    fullname(): string | undefined {
      if (store.state.userInfo !== undefined) {
        return store.state.userInfo.fullname ?? store.state.userInfo.username
      }
      return undefined
    },
    isChristmas(): boolean {
      const date = new Date()
      return date.getMonth() === 11 && date.getDate() >= 20 && date.getDate() <= 30
    },
    appBarColor(): string {
      if (this.isChristmas) {
        return 'info lighten-1'
      } else {
        return 'primary darken-1'
      }
    },
    modeNoProd() {
      if (process.env.VUE_APP_MODE === 'prod') {
        return ''
      } else {
        return process.env.VUE_APP_MODE?.toUpperCase()
      }
    },
  },
  mounted() {
    if (this.isChristmas) {
      this.christmasSound = new Audio(christmasSound)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.christmasSound!.currentTime = 56 // magic
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.christmasSound!.loop = true
    }
  },
})
