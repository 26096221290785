import { isArray } from 'lodash'

export function isRequiredGenerator(message: string) {
  return (v: string): string | true => !!v || message
}

export const isRequired = isRequiredGenerator('Le champ est requis')

export function isPositiveNumber(v: number | string): true | string {
  return (v >= 0 && /^(?:\d+|\d*\.\d+)$/.test(v.toString())) || 'La valeur doit être un nombre positif'
}

export function isPositiveNumberOrNull(v: number | string): true | string {
  return (!v || v >= 0 && /^(?:\d+|\d*\.\d+)$/.test(v.toString())) || 'La valeur doit être un nombre positif'
}

export function arePositiveIntegers(v: string | number | (number | string)[]): true | string {
  const nv = isArray(v) ? v : [v]
  return nv.every(w => Number(w) >= 0 && Number.isInteger(Number(w))) || 'La valeur doit être un entier positif'
}

export function arePositiveFiniteNumber(v: string | number | (number | string)[]): true | string {
  const nv = isArray(v) ? v : [v]
  return nv.every(w => Number(w) >= 0 && Number.isFinite(Number(w))) || 'La valeur doit être un nombre positif'
}

export function isPercentage(v: number): true | string {
  return v >= 0 && v <= 100 || 'La valeur doit être comprise entre 0 et 100'
}

export function isBetween2and100(v: number): true | string {
  return v >= 2 && v <= 100 || 'La valeur doit être comprise entre 2 et 100'
}

export function atLeastOneSelectionGenerator(messageError: string) {
  return (v: unknown[]): string | true => v.length > 0 || messageError
}

export function isYear(v: number): true | string {
  return !v || (arePositiveIntegers(v) && v.toString().length === 4) || 'Le nombre doit faire 4 chiffres'
}

export function validSireIdRule(v: string): boolean | string {
  return !v || v.match('^\\d{8}\\w$') != null || 'Numéro SIRE invalide'
}

export function rulePasswordNumber(v: string) : boolean | string {
  return !v || /[0-9]/.test(v) || 'Le mot de passe doit contenir au moins un chiffre'
}

export function rulePasswordLowerCase(v: string) : boolean | string {
  return !v || /[a-z]/.test(v) || 'Le mot de passe doit contenir au moins une minuscule'
}

export function rulePasswordHupperCase(v: string) : boolean | string {
  return !v || /[A-Z]/.test(v) || 'Le mot de passe doit contenir au moins une majuscule'
}

export function rulePasswordSpecialCase(v: string) : boolean | string {
  return !v || /[[\]$&+,:;=?@#|'<>.\-^*()%!]/.test(v) || 'Le mot de passe doit contenir au moins un caractère spécial'
}

export function rulePasswordEightCharactersLong(v: string) : boolean | string {
  return !v || v.length >= 8 || 'Le mot de passe doit contenir au moins 8 caractères'
}

export function ruleRequired(v: string) : boolean | string {
  return !!v || 'Le mot de passe est obligatoire'
}

export function isTwoDecimal(v: string): true | string {
  return !v || /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/gm.test(v.toString()) || 'Le format attendu contient 2 décimales au maximum'
}

export function hasMinimalSize(size: number, message = `Vous devez avoir au minimum ${size} élément(s)`): (v: { length: number }) => boolean|string {
  return (v: { length: number }) => !v || v.length >= size || message
}

export function isValidChequeNumber(v: string) : boolean | string {
  return !v || /^\d{7}$/.test(v) || 'Le numéro de cheque doit comporter 7 chiffres'
}

export function isValidFrfNumber(v: string) : boolean | string {
  return !v || /^\d{7}[a-zA-Z]{1,2}$/.test(v) || 'Le numéro FRF doit comporter 7 chiffres et 1 ou 2 lettres'
}

export const rulePassword: ((v:string) => boolean | string)[] = [
  rulePasswordNumber,
  rulePasswordLowerCase,
  rulePasswordHupperCase,
  rulePasswordSpecialCase,
  rulePasswordEightCharactersLong,
]

export default { isRequired, isRequiredGenerator, arePositiveIntegers, isPositiveNumber, isPercentage, isBetween2and100, atLeastOneSelectionGenerator, isYear, arePositiveFiniteNumber, isTwoDecimal, isValidChequeNumber, hasMinimalSize, isValidFrfNumber, isPositiveNumberOrNull }
