
import Vue from 'vue'
import { AuthenticationResult } from '@azure/msal-browser'
import msalInstance from '@/msal-config'
import { logException } from '@/utils/log'
import { isSafari } from '@/utils/brower'

export default Vue.extend({
  props: ['loading', 'disabled'],
  name: 'SsoButton',
  methods: {
    logInSso(): void {
      this.$emit('click')
      if (isSafari()) {
        msalInstance
          .loginRedirect({
            scopes: ['User.Read'],
            redirectUri: `${process.env.VUE_APP_URL_SSO}/login`,
          })
      } else {
        msalInstance
          .loginPopup({
            scopes: ['User.Read'],
          })
          .then((responseSso: AuthenticationResult) => {
            this.$emit('login-sso', responseSso)
          })
          .catch((error) => {
            this.$emit('error')
            logException(error)
          })
      }
    },
  },
})
