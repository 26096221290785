interface SnackbarInfo {
  active?: boolean
  text: string
  color?: string
  timeout?: number
}

const defaultSnackbarInfo : SnackbarInfo = {
  active: true,
  text: '',
  color: 'error',
  timeout: 5000,
}

const snackbarStore = {
  debug: true,
  state: {
    snackbar: { active: false, text: '' } as SnackbarInfo,
  },
  setSnackbar(value: SnackbarInfo): void {
    const v = { ...defaultSnackbarInfo, ...value }
    this.state.snackbar.text = v.text
    this.state.snackbar.active = v.active
    this.state.snackbar.timeout = v.timeout
    this.state.snackbar.color = v.color
  },
  setSuccess(message: string): void {
    this.state.snackbar.text = message
    this.state.snackbar.active = defaultSnackbarInfo.active
    this.state.snackbar.timeout = defaultSnackbarInfo.timeout
    this.state.snackbar.color = 'success'
  },
  setError(message: string): void {
    this.state.snackbar.text = message
    this.state.snackbar.active = defaultSnackbarInfo.active
    this.state.snackbar.timeout = defaultSnackbarInfo.timeout
    this.state.snackbar.color = 'error'
  },
}

export default snackbarStore
