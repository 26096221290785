
import store from '@/store'
import Vue from 'vue'

export default Vue.extend({
  mounted() {
    store.logout()
    this.$router.push('/login')
  },
})
